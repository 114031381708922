import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiGetVoteList, apiGetVoteDetails } from "../../utils/api/api";
import { SNACKBAR_ERROR, RECORD_DISPLAY_LIMIT } from "../../utils/constants/constants";
import { apiResendVote, apiCloseVote } from "../../utils/api/api";
import InvestorVotingsDrawer from "./InvestorVotingsDrawer";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { apiVotesDownload } from "../../utils/api/api";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
  Skeleton,
  CircularProgress,
  Divider,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { isSubAdmin } from "../../utils/StringUtil";

function InvestorVotingList() {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [voteDetails, setVoteDetails] = useState({});
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [activeVoteDetails, setActiveVoteDetails] = useState([]);
  const [pastVoteDetails, setPastVoteDetails] = useState([]);
  const userType = process.env.REACT_APP_USER_TYPE;
  const [actionLoader, setActionLoader] = useState(false);

  useEffect(() => {
    getVoteList();
  }, [open]);

  const getVoteList = () => {
    setIsLoading(true);
    apiGetVoteList()
      .then((data) => {
        setIsLoading(false);
        setActiveVoteDetails(data.activeVotingList);
        setPastVoteDetails(data.pastVotingList);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    expanded !== null && getVoteDetails();
  }, [expanded]);

  const getVoteDetails = (param = {}) => {
    setIsDetailsLoading(true);
    param.voteRid = expanded;
    apiGetVoteDetails(param)
      .then((data) => {
        setIsDetailsLoading(false);
        setVoteDetails(data);
      })
      .catch((error) => {
        setIsDetailsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const handleResend = async (voteRid) => {
    if (!voteRid) {
      enqueueSnackbar("Invalid vote ID.", SNACKBAR_ERROR);
      return;
    }

    setActionLoader(true);
    try {
      await apiResendVote(voteRid);
      enqueueSnackbar("Resent to pending mails!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error resending.", SNACKBAR_ERROR);
    } finally {
      setActionLoader(false);
    }
  };

  const handleClose = async (voteRid) => {
    if (!voteRid) {
      enqueueSnackbar("Invalid vote ID.", SNACKBAR_ERROR);
      return;
    }

    setActionLoader(true);
    try {
      await apiCloseVote(voteRid);
      getVoteList()
      enqueueSnackbar("Vote Closed Successfully!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error Closing.", SNACKBAR_ERROR);
    } finally {
      setActionLoader(false);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const downloadFile = async (response) => {
    const contentType = response.headers.get('content-type');
    const contentDisposition = response.headers.get('content-disposition');

    if (!contentType || !contentDisposition) {
      console.error("Missing headers in response", response.headers);
      return;
    }

    const blob = new Blob([response.data], { type: contentType });
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    const fileName = contentDisposition.split('"')[1] || 'downloaded-file';
    downloadLink.setAttribute('download', fileName);
    downloadLink.click();
    window.URL.revokeObjectURL(url);
  };

  const downloadVoteDetails = (voteDetails) => {
    const params = {
      voteRid: voteDetails?.voteDetail?.voteRid
    };

    apiVotesDownload(params)
      .then((data) => {
        downloadFile(data);
      })
      .catch((error) => {
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  const stripHtml = (html) => {
    if (!html) return "";
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  return (
    <Layout title="Investor Voting">
      {userType === "ADMIN" && !isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            zIndex: 10,
            bottom: "20px",
            right: "20px",
          }}
          onClick={() => setOpen(true)}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}
      <div>
        <div className="p-4" style={{ marginTop: "5px", marginLeft: "2rem", marginRight: "2rem" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangular"
                height={40}
              />
            ))
          ) : (
            <Stack>
              <p className="fs-large-semibold">Active Votes</p>
              {activeVoteDetails?.map((data) => (
                <Accordion
                  key={data.voteRid}
                  expanded={expanded === data.voteRid}
                  onChange={handleChange(data.voteRid)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls={`panel-${data.voteRid}-content`}
                    id={`panel-${data.voteRid}-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <h5 className="fs-med-semibold p-0 m-0">{data.subject}</h5>
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadVoteDetails(voteDetails);
                        }}
                        startIcon={<GetAppIcon />}
                        disabled={isLoading}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isDetailsLoading ? (
                      <CircularProgress />
                    ) : (
                      <div>
                        {expanded === data?.voteRid && voteDetails ? (
                          <div>
                            <p className="fs-med-medium">{stripHtml(voteDetails?.voteDetail?.body)}</p>
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                              <p className="fs-med-medium" style={{ marginBottom: "0rem" }}>Documents:</p>
                              {voteDetails?.voteDetail?.attachment?.length > 0 ? (
                                voteDetails?.voteDetail?.attachment.map((doc, key) => (
                                  <Tooltip title={doc} key={key}>
                                    <a href={doc} target="_blank" rel="noopener noreferrer">
                                      <AttachFileIcon color="secondary" />
                                    </a>
                                  </Tooltip>
                                ))
                              ) : (
                                <Typography>No Attachments</Typography>
                              )}
                            </Box>

                            <Box sx={{ marginTop: "10px" }}>
                              {voteDetails.votePercentageDetails?.map(({ label, votePercentage }, index) => (
                                <Box key={index} sx={{ marginBottom: "8px", display: "flex", width: "200px" }}>
                                  <Typography sx={{ fontWeight: "bold", minWidth: "100px", maxWidth: "100px", overflow: "hidden" }}>
                                    {label}:
                                  </Typography>
                                  <Typography sx={{ fontWeight: "bold" }}>{votePercentage.toFixed(2)}%</Typography>
                                </Box>
                              ))}
                            </Box>
                            <div style={{ disply: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                              {data.isClosed !== 1 ? (
                                <>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ color: "white", }}
                                    onClick={() => handleResend(voteDetails?.voteDetail?.voteRid)}
                                    disabled={actionLoader}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Resend
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ color: "white", }}
                                    onClick={() => handleClose(voteDetails?.voteDetail?.voteRid)}
                                    disabled={actionLoader}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Close
                                  </Button>
                                </>
                              ) : (
                                <div>
                                  <p style={{ color: "red" }} className="fs-large-semibold">Vote is Closed</p>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : (
                          <p className="fs-med-regular p-0 m-0">No Data</p>
                        )}
                      </div>)}
                  </AccordionDetails>
                </Accordion>
              ))}
              <Divider style={{ height: "1px", backgroundColor: "rgba(0, 0, 0, 0.12)", marginTop: "40px" }} />
              <p className="fs-large-semibold" style={{ marginTop: "20px" }}>Past Votes</p>
              {pastVoteDetails?.map((data) => (
                <Accordion key={data.voteRid}
                  expanded={expanded === data.voteRid}
                  onChange={handleChange(data.voteRid)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    aria-controls={`panel-${data.voteRid}-content`}
                    id={`panel-${data.voteRid}-header`}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <h5 className="fs-med-semibold p-0 m-0">{data.subject}</h5>
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadVoteDetails(voteDetails);
                        }}
                        startIcon={<GetAppIcon />}
                        disabled={isLoading}
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    {expanded === data?.voteRid && voteDetails ? (
                      <div>
                        <p className="fs-med-medium">{stripHtml(voteDetails?.voteDetail?.body)}</p>
                        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <p className="fs-med-medium" style={{ marginBottom: "0rem" }}>Documents:</p>
                          {voteDetails?.voteDetail?.attachment?.length > 0 ? (
                            voteDetails?.voteDetail?.attachment.map((doc, key) => (
                              <Tooltip title={doc} key={key}>
                                <a href={doc} target="_blank" rel="noopener noreferrer">
                                  <AttachFileIcon color="secondary" />
                                </a>
                              </Tooltip>
                            ))
                          ) : (
                            <Typography>No Attachments</Typography>
                          )}
                        </Box>

                        <Box sx={{ marginTop: "10px" }}>
                          {voteDetails.votePercentageDetails?.map(({ label, votePercentage }, index) => (
                            <Box key={index} sx={{ marginBottom: "8px", display: "flex", width: "200px" }}>
                              <Typography sx={{ fontWeight: "bold", minWidth: "100px", maxWidth: "100px", overflow: "hidden" }}>
                                {label}:
                              </Typography>
                              <Typography sx={{ fontWeight: "bold" }}> {votePercentage.toFixed(2)}%</Typography>
                            </Box>
                          ))}
                        </Box>
                        <div style={{ disply: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                          {data.isClosed !== 1 ? (
                            <>
                              <Button
                                variant="contained"
                                color="secondary"
                                sx={{ color: "white", }}
                                onClick={() => handleResend(voteDetails?.voteDetail?.voteRid)}
                                disabled={actionLoader}
                                style={{ marginRight: "10px" }}
                              >
                                Resend
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                sx={{ color: "white", }}
                                onClick={() => handleClose(voteDetails?.voteDetail?.voteRid)}
                                disabled={actionLoader}
                                style={{ marginRight: "10px" }}
                              >
                                Close
                              </Button>
                            </>
                          ) : (
                            <div>
                              <p style={{ color: "red" }} className="fs-large-semibold">Vote is Closed</p>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="fs-med-regular p-0 m-0">No Data</p>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}

              <InvestorVotingsDrawer open={open} onClose={() => setOpen(false)} handleSave={() => setOpen(false)} />
            </Stack>)}
        </div>
      </div>
    </Layout>
  );
}

export default InvestorVotingList;
