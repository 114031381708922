import React, { useState, useEffect, useCallback } from 'react';
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import { Editor } from '@tinymce/tinymce-react';
import { RemoveCircle } from "@mui/icons-material";
import { OPEN_SCHEMES, CLOSED_SCHEMES } from "../../utils/constants/constants";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    Badge,
    CircularProgress,
    OutlinedInput,
    Checkbox,
    ListItemText,
    FormHelperText,
    Switch,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import { apiGetInvestorList } from '../../utils/api/api';
import DrawerLayout from '../../components/layout/DrawerLayout';
import { ACTIVE, DORMANT } from '../../utils/constants/constants';
import { BASE_URL, TINY_MCE_API_KEY, SNACKBAR_ERROR } from "../../utils/constants/constants";
import { uploadImage, uploadMultipleFile, getOpportunitySimple, getPartners, apiSaveVote } from "../../utils/api/api";

const MAX_VOTE_OPTIONS = 12;
const MAX_DESCRIPTION_LENGTH = 50;

function InvestorVotingsDrawer({ open, onClose, handleSave }) {
    const [status, setStatus] = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [subject, setSubject] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [isInvestorLoading, setIsInvestorLoading] = useState(false)
    const [investorList, setInvestorList] = useState([]);
    const [persistentSchemeFilters, setPersistentSchemeFilters] = useState([]);
    const [filterPartner, setFilterPartner] = useState([]);
    const [mailBody, setMailBody] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [partnerList, setPartnerList] = useState([]);
    const [filterScheme, setFilterScheme] = useState([]);
    const [actionLoader, setActionLoader] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [filterSchemeList, setFilterSchemeList] = useState([]);
    const [votingOptions, setVotingOptions] = useState([
        { votingId: 1, votingname: "VoteOption1", description: "" },
        { votingId: 2, votingname: "VoteOption2", description: "" }
    ]);
    const [isInvestorListVisible, setIsInvestorListVisible] = useState(false);

    useEffect(() => {
        setSubject("");
        setMailBody(null);
        setAttachments([]);
        setVotingOptions([
            { votingId: 1, votingname: "VoteOption1", description: "" },
            { votingId: 2, votingname: "VoteOption2", description: "" }
        ]);
        setStatus();
        setFilterPartner([]);
        setFilterScheme([]);
    }, [open]);

    useEffect(() => {
        getFilterSchemes().then(() => {
            if (persistentSchemeFilters.length > 0 && filterPartner.length > 0) {
                setFilterScheme(persistentSchemeFilters);
            }
        });
    }, []);

    useEffect(() => {
        getFilterSchemes();
    }, [filterPartner]);

    const getPartnersList = useCallback(() => {
        setPartnerList([]);
        getPartners()
            .then((res) => {
                setPartnerList(res.partnerMasterList);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    }, [enqueueSnackbar]);

    useEffect(() => {
        getPartnersList();
    }, [getPartnersList]);

    const getFilterSchemes = async () => {
        try {
            const initialParams = {
                page: 1,
                limit: 2000,
                schemeStatus: `${OPEN_SCHEMES},${CLOSED_SCHEMES}`,
                schemeName: ''
            };

            const initialResponse = await getOpportunitySimple(initialParams);
            let allSchemes = [];

            if (initialResponse?.opportunityList) {
                allSchemes = initialResponse.opportunityList.map(opportunity => ({
                    schemeRid: opportunity.oppRid,
                    schemeName: opportunity.oppTitle
                }));

                if (initialResponse.totalCount > 2000) {
                    const remainingPages = Math.ceil((initialResponse.totalCount - 2000) / 2000);
                    const additionalRequests = Array.from({ length: remainingPages }, (_, i) => i + 2)
                        .map(page => getOpportunitySimple({
                            page,
                            limit: 2000,
                            schemeStatus: '',
                            schemeName: ''
                        }));

                    const additionalResponses = await Promise.all(additionalRequests);
                    additionalResponses.forEach(response => {
                        if (response?.opportunityList) {
                            allSchemes = [...allSchemes,
                            ...response.opportunityList.map(opportunity => ({
                                schemeRid: opportunity.oppRid,
                                schemeName: opportunity.oppTitle
                            }))
                            ];
                        }
                    });
                }

                allSchemes.sort((a, b) => a.schemeName.localeCompare(b.schemeName));
                setFilterSchemeList(allSchemes);
            }
        } catch (error) {
            enqueueSnackbar(error, SNACKBAR_ERROR);
        }
    };

    const handleDescriptionChange = (event, votingId) => {
        const { value } = event.target;

        setVotingOptions((prevOptions) =>
            prevOptions.map((option) =>
                option.votingId === votingId
                    ? {
                        ...option,
                        description: value,
                        error: value.length > MAX_DESCRIPTION_LENGTH
                    }
                    : option
            )
        );
    };

    const addVotingOption = () => {
        if (votingOptions.length < MAX_VOTE_OPTIONS) {
            setVotingOptions((prevOptions) => [
                ...prevOptions,
                {
                    votingId: prevOptions.length + 1,
                    votingname: `VoteOption${prevOptions.length + 1}`,
                    description: "",
                },
            ]);
        }
    };

    const handleClose = () => {
        setVotingOptions([
            { votingId: 1, votingname: "VoteOption1", description: "" },
            { votingId: 2, votingname: "VoteOption2", description: "" }
        ]);
        if (typeof onClose === "function") {
            onClose();
        }
    };

    const deleteAttachment = (index) => {
        const newList = [...attachments];
        newList.splice(index, 1);
        setAttachments(newList);
    }

    const onSubmit = async () => {
        if (!subject.trim()) {
            enqueueSnackbar("Subject is required.", SNACKBAR_ERROR);
            return;
        }

        if (!mailBody || mailBody.trim() === "") {
            enqueueSnackbar("Email body cannot be empty.", SNACKBAR_ERROR);
            return;
        }

        if (votingOptions.length < 2) {
            enqueueSnackbar("At least two voting options are required.", SNACKBAR_ERROR);
            return;
        }

        for (let option of votingOptions) {
            if (!option.description.trim()) {
                enqueueSnackbar(`Description is required for voting option "${option.votingname}".`, SNACKBAR_ERROR);
                return;
            }

            if (option.description.length > MAX_DESCRIPTION_LENGTH) {
                enqueueSnackbar(`"${option.votingname}" exceeds ${MAX_DESCRIPTION_LENGTH} characters.`, SNACKBAR_ERROR);
                return;
            }

            if (filterPartner.length === 0 && filterScheme.length === 0 && status.length === 0) {
                enqueueSnackbar("Select at least one Partner or Scheme or Status.", SNACKBAR_ERROR);
                return;
            }
        }
        setActionLoader(true);
        const reqBody = {
            subject: subject,
            body: mailBody,
            attachment: attachments,
            voteOptionsList: votingOptions.map(option => ({
                label: option.description
            })),
            voteOptionsList: votingOptions.map(option => ({
                label: option.votingname
            })),
            investorStatus: status ? status : null,
            partnerRids: filterPartner.join(","),
            schemeRids: filterScheme.join(",")
        };

        try {
            await apiSaveVote(reqBody);
            enqueueSnackbar("Investor voting saved successfully!", { variant: "success" });
            handleSave();
        } catch (error) {
            enqueueSnackbar("Error saving investor voting.", SNACKBAR_ERROR);
        } finally {
            setActionLoader(false);
        }
    };

    const openDocExplorer = () => {
        document.getElementById('input-file').click();
    }

    const uploadAttachments = (e) => {
        setIsFileLoading(true);
        uploadMultipleFile(e.target.files)
            .then((res) => {
                setIsFileLoading(false);
                setAttachments((prevAttachments) => [
                    ...prevAttachments,
                    ...res.data.uploadedFiles
                ]);
            })
            .catch((e) => {
                setIsFileLoading(false);
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    useEffect(() => {
        fetchData();
    }, [status, filterPartner, filterScheme]);

    const fetchData = async () => {
        if (!status && filterPartner.length === 0 && filterScheme.length === 0) {
            setInvestorList([]);
            return;
        }
        setIsInvestorLoading(true);
        try {
            const params = {
                status: status || null,
                partnerRid: filterPartner.join(","),
                schemeRid: filterScheme.join(","),
            };
            const response = await apiGetInvestorList(params);
            setInvestorList(response.investorList || []);
        } catch (error) {
            console.error("Error fetching investors:", error);
            setInvestorList([]);
        }
        setIsInvestorLoading(false);
    };

    const handleAccordionToggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        setIsInvestorListVisible(false);
    }, [status, filterPartner, filterScheme]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DrawerLayout
                open={open}
                disableEnforceFocus={true}
                title={'Investor Voting'}
                actionLoader={actionLoader}
                closeAction={handleClose}
                negativeAction={{ label: 'Cancel', onClick: handleClose }}
                positiveAction={{ label: 'Save', onClick: onSubmit }}
            >
                <Stack sx={{ marginBottom: "1rem", alignItems: "center" }} direction="row" spacing={2}>
                    <FormControl sx={{ flex: 1 }}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            input={<OutlinedInput label="Status" />}
                            renderValue={(selected) =>
                                selected === ACTIVE ? "Active" : "Dormant"
                            }
                        >
                            {[ACTIVE, DORMANT].map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={status === option} />
                                    <ListItemText primary={option === ACTIVE ? "Active" : "Dormant"} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }} size="large">
                        <InputLabel id="demo-multiple-checkbox-label">Filter By Partner</InputLabel>
                        <Select
                            multiple
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            value={filterPartner}
                            onChange={(e) => {
                                const newPartners = e.target.value;
                                setFilterPartner(newPartners);
                            }}
                            input={<OutlinedInput label="Filter By Partner" />}
                            renderValue={(selected) => `${selected.length} Selected`}
                        >
                            {partnerList.map((data, key) => (
                                <MenuItem key={key} value={data.partnerRid}>
                                    <Checkbox checked={filterPartner.indexOf(data.partnerRid) > -1} />
                                    <ListItemText primary={data.fullName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ flex: 1 }} size="large">
                        <InputLabel id="scheme-filter-label">Filter By Scheme</InputLabel>
                        <Select
                            multiple
                            labelId="scheme-filter-label"
                            id="scheme-filter"
                            value={filterScheme}
                            onChange={(e) => {
                                const newSchemes = e.target.value;
                                setFilterScheme(newSchemes);
                            }}
                            input={<OutlinedInput label="Filter By Scheme" />}
                            renderValue={(selected) => `${selected.length} Selected`}
                        >
                            {filterSchemeList.map((scheme, key) => (
                                <MenuItem key={key} value={scheme.schemeRid}>
                                    <Checkbox checked={filterScheme.indexOf(scheme.schemeRid) > -1} />
                                    <ListItemText primary={scheme.schemeName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <Stack style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Accordion expanded={expanded} onChange={handleAccordionToggle}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ fontWeight: "bold", flex: 1, textAlign: "left", marginLeft: "10px", paddingRight: "5px" }}>
                                Investor Name
                            </Typography>
                            <Typography sx={{ fontWeight: "bold", flex: 1, textAlign: "left" }}>
                                Investor Email
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack sx={{ overflowY: "auto", gap: "10px", padding: "5px" }}>

                                {isInvestorLoading ? (
                                    <CircularProgress size={24} sx={{ alignSelf: "center" }} />
                                ) : investorList.length === 0 ? (
                                    <Typography textAlign="center">No data</Typography>
                                ) : (
                                    <Stack sx={{ height: "150px", overflowY: "auto", gap: "10px", padding: "5px" }}>

                                        {investorList.map((investor) => (
                                            <Paper
                                                key={investor.invRid}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    padding: "10px",
                                                    boxShadow: "0px 1px 6px rgba(0,0,0,0.07)",
                                                    borderRadius: "5px",
                                                }}
                                            >
                                                <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", flex: 1, textAlign: "left" }}>
                                                    {investor.fullName}
                                                </Typography>
                                                <Typography sx={{ flex: 1, textAlign: "left", whiteSpace: "nowrap" }}>
                                                    {investor.email}
                                                </Typography>
                                            </Paper>
                                        ))}
                                    </Stack>
                                )}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1.5 }}
                        id="outlined-basic"
                        name="subject"
                        label="Subject"
                        variant="outlined"
                        value={subject}
                        fullWidth
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="column" spacing={2}>
                    <Editor
                        value={mailBody}
                        apiKey={TINY_MCE_API_KEY}
                        init={{
                            height: 400,
                            menubar: 'file edit view insert format tools table help',
                            plugins:
                                'advlist autolink lists link image charmap print preview anchor ' +
                                'searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount',
                            toolbar:
                                'undo redo | formatselect | bold italic underline strikethrough | ' +
                                'alignleft aligncenter alignright alignjustify | ' +
                                'bullist numlist outdent indent | ' +
                                'link image media | ' +
                                'code | ' +
                                'table | ' +
                                'removeformat | ' +
                                'fullscreen',
                            images_upload_handler: async blobInfo => {
                                return new Promise((resolve, reject) => {
                                    uploadImage(blobInfo.blob())
                                        .then((res) => {
                                            const { path } = res.data;
                                            resolve(`${BASE_URL}/${path}`);
                                        })
                                        .catch((err) => {
                                            enqueueSnackbar(err, SNACKBAR_ERROR);
                                            reject(err);
                                        });
                                });
                            },
                            content_style:
                                'body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }',
                        }}
                        onEditorChange={(c, e) => setMailBody(c)}
                    />
                </Stack>
                <Stack sx={{ marginBottom: "1rem", marginTop: "1rem" }} direction="column" spacing={2}>
                    <p className="section-label p-0 m-0">Upload Documents</p>
                    <input
                        className="d-none"
                        id="input-file"
                        type="file"
                        multiple
                        accept=".pdf"
                        onClick={(e) => {
                            e.target.value = null;
                        }}
                        onChange={(e) => {
                            uploadAttachments(e);
                        }}
                    />
                    <Stack sx={{ flex: 1, alignItems: 'center', flexWrap: 'wrap', gap: 1 }} direction="row">
                        {attachments?.map((data, key) => {
                            return (
                                <Tooltip title={data.actualFileName} key={key}>
                                    <Badge
                                        badgeContent={<RemoveCircle fontSize="10" onClick={() => deleteAttachment(key)} />}
                                        color="error">
                                        <AttachFileIcon color="secondary" />
                                    </Badge>
                                </Tooltip>
                            );
                        })}
                        {isFileLoading ? <CircularProgress color="secondary" /> :
                            <IconButton color="secondary" onClick={() => openDocExplorer()}>
                                <AddIcon />
                            </IconButton>}
                    </Stack>
                </Stack>
                <Stack sx={{ flex: 1, flexWrap: "wrap", gap: 1, display: "flex", flexDirection: "column", justifyContent: "flex-start" }} direction="row">
                    <p className="section-label p-0 m-0">Voting Options</p>
                    {votingOptions.map((vote, index) => (
                        <div key={vote.votingId} style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                            <span style={{ fontSize: "20px", fontWeight: "bold" }}>•</span>
                            <p style={{ marginBottom: "0rem", flex: 0.12 }}>{vote.votingname}</p>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Enter Option"
                                value={vote.description}
                                onChange={(e) => handleDescriptionChange(e, vote.votingId)}
                                sx={{ flex: 0.5 }}
                            />
                            <div style={{ flex: 0.5 }}>
                                {vote.error && (
                                    <FormHelperText sx={{ color: "red" }}>
                                        Max 50 characters allowed!
                                    </FormHelperText>
                                )}
                            </div>
                        </div>
                    ))}
                    {votingOptions.length < MAX_VOTE_OPTIONS && (
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingLeft: "25px" }} onClick={addVotingOption}>
                            <IconButton color="secondary">
                                <AddIcon />
                            </IconButton>
                        </div>
                    )}
                </Stack>
            </DrawerLayout>
        </LocalizationProvider>
    )
}

export default InvestorVotingsDrawer