import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import { DatePicker } from "@mui/x-date-pickers";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import { useSnackbar } from 'notistack';
import { Autocomplete, Divider, Stack, TextField, Box } from "@mui/material";
import { apiInvestorOpportunity, apiSaveUnitTransfer, getInvestors, getOpportunity } from "../../utils/api/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const UnitTransferModal = ({ open, onClose, isEdit, unitTransfer, handleSave }) => {
    const [schemeList, setSchemeList] = useState([]);
    const [invOppList, setInvOppList] = useState([]);
    const [invList, setInvList] = useState([]);
    const [oppRid, setOppRid] = useState(null);
    const [investor, setInvestor] = useState(null);
    const [investorOpportunity, setInvestorOpportunity] = useState(null);
    const [amount, setAmount] = useState();
    const [unitValue, setUnitValue] = useState();
    const [marketValue, setMarketValue] = useState();
    const [unitTransferDate, setUnitTransferDate] = useState(
        new Date().toISOString().split('T')[0]
    );
    const [remark, setRemark] = useState('');
    const [actionLoader, setActionLoader] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isSaving, setIsSaving] = useState(false);

    const handleUnitTransferDate = (selectedDate) => {
        const date = new Date(selectedDate?.$d);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setUnitTransferDate(formattedDate);
    }

    const getSchemeList = (param = {}) => {
        param.pageNumber = 0;
        param.oppStatus = 1503;
        getOpportunity(param)
            .then((data) => {
                setSchemeList(data.opportunityList);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR)
            });
    }

    const getInvestorOpportunity = (param = {}) => {
        param.oppRid = oppRid;
        apiInvestorOpportunity(param)
            .then((data) => {
                setInvOppList(data.investorOpportunities);
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }

    const getInvestorList = (param = {}) => {
        param.mcaStatus = 1;
        getInvestors(param)
            .then((data) => {
                setInvList(
                    data.investorList.filter((obj) => obj.invRid !== investorOpportunity?.invRid)
                );
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            });
    }

    useEffect(() => {
        if (investorOpportunity !== null) {
            getInvestorList();
            setInvestor(null);
        }
    }, [investorOpportunity]);

    useEffect(() => {
        if (oppRid !== null) {
            getInvestorOpportunity();
            setInvestorOpportunity(null);
            setInvList([]);
            setInvestor(null);
        }
    }, [oppRid]);

    useEffect(() => {
        if (open) {
            setIsSaving(false);
            getSchemeList();
            setInvOppList([]);
            setInvList([]);
            setOppRid(null);
            setInvestor(null);
            setInvestorOpportunity(null);
            setAmount(0);
            setMarketValue(0);
            setRemark('');
            setUnitValue()
        }
    }, [open]);

    const saveUnitTransfer = () => {
        if (isSaving) return;

        setIsSaving(true);
        setActionLoader(true);
        const reqBody = {
            "sellIoRid": investorOpportunity.ioRid,
            "buyerInvRid": investor.invRid,
            "amount": amount,
            "marketValue": marketValue,
            "date": unitTransferDate,
            "remark": remark,
            "units": unitValue,
        }

        apiSaveUnitTransfer(reqBody)
            .then((data) => {
                enqueueSnackbar(data, SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
                enqueueSnackbar(error, SNACKBAR_ERROR);
            })
            .finally(() => {
                setIsSaving(false);
                setActionLoader(false);
            });
    };

    const onSubmit = () => {
        if (oppRid === null) {
            enqueueSnackbar("Please select scheme", SNACKBAR_WARNING);
        } else if (investorOpportunity === null) {
            enqueueSnackbar("Please select selling investor", SNACKBAR_WARNING);
        } else if (investor === null) {
            enqueueSnackbar("Please select buying investor", SNACKBAR_WARNING);
        } else if (parseFloat(amount) <= 0) {
            enqueueSnackbar("Invalid amount", SNACKBAR_WARNING);
        } else if (parseFloat(marketValue) <= 0) {
            enqueueSnackbar("Invalid Market Value", SNACKBAR_WARNING);
        } else {
            saveUnitTransfer();
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DrawerLayout
                open={open}
                title="Unit Transfer"
                drawerWidth="60%"
                actionLoader={actionLoader}
                closeAction={onClose}
                negativeAction={{
                    label: 'Cancel',
                    onClick: onClose,
                    disabled: isSaving
                }}
                positiveAction={{
                    label: 'Save',
                    onClick: onSubmit,
                    disabled: isSaving
                }}
            >
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        name="oppRid"
                        variant="outlined"
                        options={schemeList}
                        getOptionLabel={(option) => option.oppTitle}
                        value={schemeList.find((option) => option.oppRid === oppRid) || null}
                        fullWidth
                        onChange={(e, v) => setOppRid(v.oppRid)}
                        renderInput={(params) => <TextField {...params} label="Scheme" />}
                        renderOption={(props, option) => (
                            <li {...props} key={option.oppRid}>{option.oppTitle}</li>
                        )}
                    />
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        name="investorOpportunity"
                        variant="outlined"
                        options={invOppList}
                        getOptionLabel={(option) => option.invFullName}
                        value={invOppList.find((option) => option.ioRid === investorOpportunity?.ioRid) || null}
                        fullWidth
                        onChange={(e, v) => setInvestorOpportunity(v)}
                        renderInput={(params) => <TextField {...params} label="Seller" />}
                        renderOption={(props, option) => (
                            <div className="d-flex align-items-center" {...props} key={option.ioRid}>
                                <AccountCircleIcon color="primary" fontSize="large" />
                                <div className="flex-grow-1 ms-2">
                                    <p className="fs-med-medium p-0 m-0">{option.invFullName}</p>
                                    <p className="fs-small-regular text-muted p-0 m-0">{option.invCode} • {option.className}</p>
                                </div>
                            </div>
                        )}
                    />
                    <Autocomplete
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        name="investor"
                        variant="outlined"
                        options={invList}
                        getOptionLabel={(option) => option.fullName}
                        value={invList.find((option) => option.invRid === investor?.invRid) || null}
                        fullWidth
                        onChange={(e, v) => setInvestor(v)}
                        renderInput={(params) => <TextField {...params} label="Buyer" />}
                        renderOption={(props, option) => (
                            <div className="d-flex align-items-center" {...props} key={option.invRid}>
                                <AccountCircleIcon color="primary" fontSize="large" />
                                <div className="flex-grow-1 ms-2">
                                    <p className="fs-med-medium p-0 m-0">{option.fullName}</p>
                                    <p className="fs-small-regular text-muted p-0 m-0">{option.invCode}</p>
                                </div>
                            </div>
                        )}
                    />
                </Stack>
                <Divider sx={{ marginBottom: "1rem" }} />
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <ScoreBoard
                        label="Commitment"
                        value={investorOpportunity ? toLocalePrice(investorOpportunity?.commitment) : '---'}
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        name="amount"
                        type="number"
                        label="Amount"
                        variant="outlined"
                        value={amount}
                        onChange={(e) => {
                            setAmount(e.target.value);
                            setUnitValue((e.target.value/100000).toFixed(3))
                        }}
                        fullWidth
                    />
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1 }}
                        id="outlined-basic"
                        name="marketValue"
                        type="number"
                        label="Market Value"
                        variant="outlined"
                        value={marketValue}
                        onChange={(e) => setMarketValue(e.target.value)}
                        fullWidth
                    />
                    <ScoreBoard
                        label="units"
                        value={amount ? (amount/100000).toFixed(3) : '---'}
                    />
                </Stack>
                <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
                    <TextField
                        sx={{ flex: 1 }}
                        id="outlined-multiline-static"
                        label="Remark"
                        multiline
                        rows={4}
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                        fullWidth
                    />
                    <Box sx={{ flex: 1 }}>
                        <DatePicker
                            id="outlined-basic"
                            name="unitTransferDate"
                            label="Unit Transfer Date"
                            variant="outlined"
                            value={unitTransferDate}
                            onChange={(v) => handleUnitTransferDate(v)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            size="small"
                        />
                    </Box>
                </Stack>

                <Stack>
                </Stack>
            </DrawerLayout>
        </LocalizationProvider>
    );
}

export default UnitTransferModal;